import React from 'react';



const CardComponent = ({ card }) => {

    return (
        <div className='bg-dark card-component'>

            <div className='card-component__heading'>
                <h1 className={card[0]}>{card[0]}</h1>
            </div>

            <div className='card-component__description'>
                <p className={card[1].includes('-') ? 'negative' : 'positive'}>{card[1]}</p>
            </div>

        </div>
    );
}

export default CardComponent;
