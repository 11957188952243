import { useState, useEffect } from "react";
import { extractData } from "../utils/data";
import { BASE_URL } from "../utils/api";

export function useExcelData(uri, type = null, timeframe = null) {
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const Base_Uri = type === 'bar' ? 'https://opensheet.elk.sh/14ePg5bfMRZpy7tQZCI2K8CXreXBN97BkDYTj8nedYH4/' : BASE_URL


    useEffect(() => {
        const fetchData = async () => {
            if (uri) {
                try {
                    // Make the spinner visible while changing the timeframe
                    // setLoading(true)
                    const response = await fetch(Base_Uri + uri);
                    const data = await response.json();

                    switch (type) {
                        case 'chart':
                            const chartData = extractData(data, timeframe);
                            setData(chartData)
                            break;
                        default:
                            setData(data)
                            break;
                    }
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    setError(error)
                }
            } else {
                return null;
            }

        }

        fetchData();

    }, [uri, type, timeframe, Base_Uri])

    return { data, loading, error };
}