import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useExcelData } from '../../hooks/useExcelData';
import { heatMapChartOptions } from '../../utils/chart-tools';
import Spinner from '../Spinner';
import Error from '../Error';

const HeatMap = () => {
    const [options, setOptions] = useState({})
    const [series, setSeries] = useState([])


    const { data, loading, error } = useExcelData('API-OUT-TRADES-2')

    useEffect(() => {
        if (data) {
            const newSeries = [...data.map(item => ({
                name: item['Months'],
                data: [
                    { x: 'W1', y: item['Week 1'] },
                    { x: 'W2', y: item['Week 2'] },
                    { x: 'W3', y: item['Week 3'] },
                    { x: 'W4+', y: item['Week 4+'] }
                ]
            }))]

            setSeries(newSeries)

            const newOptions = heatMapChartOptions();
            setOptions(newOptions)
        }
    }, [data])

    // Error handling
    if (error) return <Error />

    return (
        <div className='bg-dark'>
            {loading ? (
                <Spinner />
            ) : (

                <Chart
                    options={options}
                    series={series}
                    height={400}
                    type="heatmap"
                />
            )}
        </div>
    );
}

export default HeatMap;
