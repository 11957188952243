import React from 'react';
import Dropdown from '../Dropdown';


const Menu = ({ setTimeframe, timeframes }) => {

    const handleChange = (option) => {
        setTimeframe(option.value);
    }

    const options = Object.keys(timeframes).map(key => ({
        value: key,
        label: timeframes[key].label
    }));

    return (
        <div className='bg-dark menu-container'>
            <Dropdown
                options={options}
                onSelect={handleChange}
                defaultOption={options[0]}
            />
        </div>
    );
}

export default Menu;
