import React, { useEffect, useState } from 'react';
import { useExcelData } from '../../../hooks/useExcelData';
import Spinner from '../../Spinner';
import Error from '../../Error';
import CardComponent from './CardComponent';


const CardSection = () => {
    const { data, loading, error } = useExcelData('Statistics')
    const [cards, setCards] = useState([])

    useEffect(() => {

        if (data) {
            const newCards = Object.entries(data[0]).filter(card => card[0] && card[1] && card[0] !== 'undefined' && card[1] !== 'undefined')
            newCards?.shift()
            console.log('newCards', newCards);
            setCards(newCards)
        }
    }, [data])

    // Error handling
    if (error) return <Error />

    return (
        <div className={`cards-section ${loading ? 'bg-dark' : ''}`}>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    {cards?.length > 0 ? cards.map(card => (
                        <CardComponent key={card[0]} card={card} />
                    )) : (
                        <div className='bg-dark'>
                            <h1>No Cards...</h1>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}



export default CardSection;
