import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

function Dropdown({ options, onSelect, defaultOption }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  const handleSelectOption = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="custom-select">
      <div
        className={`custom-select-label ${isOpen ? 'open' : ''}`}
        onClick={toggleDropdown}
      >
        <div className="custom-select-label-icon">
          <FontAwesomeIcon icon={faBars} color={'white'} fontSize={35} />
        </div>
        <div className="custom-select-label-text">
          <span>{selectedOption.label}</span>
          <span>▾</span>
        </div>
      </div>
      <ul className={`custom-select-options ${isOpen ? 'open' : ''}`}>
        {options.map((option) => (
          <li
            key={option.value}
            className={`custom-select-option ${option.value === selectedOption.value ? 'selected' : ''
              }`}
            onClick={() => handleSelectOption(option)}
          >
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Dropdown;