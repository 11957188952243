
import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { lineChartOptions } from "./utils/chart-tools";
import './styles/index.scss';

import Feed from "./components/Navbar/Feed";
import Menu from "./components/Navbar/Menu";
import CardSection from "./components/Panels/CardSection";
import HeatMap from "./components/Panels/HeatMap";
import BarCharts from "./components/Panels/BarCharts";
import { useExcelData } from "./hooks/useExcelData";
import Spinner from "./components/Spinner";
import Error from "./components/Error";

// const timeframes = [ 'API-OUT-15-MIN', 'API-OUT-HOUR', 'API-OUT-DAY' ];

const timeframes = {
  '15-min': { endpoint: 'API-OUT-15-MIN', label: '15 min' },
  '1-hour': { endpoint: 'API-OUT-HOUR', label: '1 hour' },
  '1-day': { endpoint: 'API-OUT-DAY', label: '1 day' }
};


function App() {
  const [options, setOptions] = useState({})
  const [series, setSeries] = useState([])
  const [timeframe, setTimeframe] = useState('1-day')

  const { data, loading, error } = useExcelData(timeframes[timeframe].endpoint, 'chart', timeframe);

  useEffect(() => {
    if (data) {
      // Options
      const newOptions = lineChartOptions(data.Date_Time);
      setOptions(newOptions)


      // Series
      const newSeries = [
        {
          name: "ORGANIC",
          data: [...data.ORGANIC]
        },
        {
          name: "FIBANN-m",
          data: [...data.FIBANN_data]
        },
        {
          name: "Hesoyam II",
          data: [...data.HESOYAMM_II_data]
        },
        {
          name: "Hesoaym II-s",
          data: [...data.HESOYAMM_II_S_data]
        },
        {
          name: "Hesoyam II-h",
          data: [...data.HESOYAMM_II_H_data]
        },
        {
          name: "Hesoyam II+",
          data: [...data.HESOYAMM_II_PLUS_data]
        },
        {
          name: "BOCANNII",
          data: [...data.BOCANNII]
        }
      ]
      setSeries(newSeries)
    }
  }, [data, timeframe])

  // Error handling
  if (error) return <Error />


  return (
    <div className="app">
      {/* Navbar */}
      <div className="navbar-container">
        <Feed />
        <Menu setTimeframe={setTimeframe} timeframes={timeframes} />
      </div>

      {/* Main Chart */}
      <div className="bg-dark">
        {loading ? <Spinner /> : (
          <Chart
            options={options}
            series={series}
            height={500}
            type="line"
          />
        )}
      </div>

      {/* Panels */}
      <div className="panels-container">
        <CardSection />
        <HeatMap />
        <BarCharts />
      </div>
    </div>
  )
}

export default App;