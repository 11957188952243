export const extractData = (data, timeframe) => {
    const newData = data;
    // console.log(newData);
    let j = newData.length;

    let days = 30;
    let lookback = days * 24 * 4;

    if (timeframe === "1-hour") {
        days = 10;
        lookback = days * 30;
    } else if (timeframe === "1-day") {
        days = 5;
        lookback = days * 10;
    }

    const FIBANN_data = [];
    const HESOYAMM_II_data = [];
    const HESOYAMM_II_S_data = [];
    const HESOYAMM_II_H_data = [];
    const HESOYAMM_II_PLUS_data = [];
    const BOCANNII = [];
    const ORGANIC = [];

    const Date_Time = [];

    while (lookback > 2) {
        FIBANN_data.push(parseFloat(newData[j - lookback]["FIBANN-m"]));
        HESOYAMM_II_data.push(parseFloat(newData[j - lookback]["Hesoyam II"]));
        HESOYAMM_II_S_data.push(parseFloat(newData[j - lookback]["Hesoaym II-s"]));
        HESOYAMM_II_H_data.push(parseFloat(newData[j - lookback]["Hesoyam II-h"]));
        HESOYAMM_II_PLUS_data.push(parseFloat(newData[j - lookback]["Hesoyam II+"]));
        BOCANNII.push(parseFloat(newData[j - lookback]["BOCANNII"]));
        ORGANIC.push(parseFloat(newData[j - lookback]["ORGANIC"]));

        Date_Time.push(newData[j - lookback]["DateTime"]);
        lookback -= 1;
    }

    const dataObj = {
        FIBANN_data,
        HESOYAMM_II_data,
        HESOYAMM_II_S_data,
        HESOYAMM_II_H_data,
        HESOYAMM_II_PLUS_data,
        BOCANNII,
        ORGANIC,
        Date_Time
    }

    

    return dataObj
}