// The function that returns the options for the Main chart
export const lineChartOptions = (categories) => {
    const newOptions = {
        chart: {
            id: "basic-line",
            type: 'line',
            zoom: {
                enabled: false,
            },
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
        },
        stroke: {
            width: 2,
        },
        dataLabels: {
            enabled: false,
        },
        tooltip: {
            theme: 'dark',
        },
        grid: {
            borderColor: '#535A6C',
            xaxis: {
                lines: {
                    show: false,
                },
            },
            yaxis: {
                lines: {
                    show: false,
                },
            }
        },
        title: {
            text: 'Доходность моделей. V 3.9.0',
            align: 'left',
            offsetY: 5,
            offsetX: 5,
        },
        colors: [
            '#a2a3a5',
            '#33ccff',
            '#8080ff',
            '#00e6b8',
            '#c5b785',
            '#d7e4de',
        ],
        xaxis: {
            categories: [...categories],
            tooltip: {
                enabled: true,
            },
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            }
        },
        legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'center',
            offsetY: 0,
        },
    }

    return newOptions
}

// The function that returns the options for the Heat Map chart
export const heatMapChartOptions = () => {
    const newOptions = {
        chart: {
            type: 'heatmap',
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            heatmap: {
                enableShades: false,
                shadeIntensity: 0.9,
                radius: 5,
                useFillColorAsStroke: true,
                colorScale: {
                    ranges: [{
                        from: 0,
                        to: 0,
                        name: 'Нет',
                        color: '#30544c',
                    },
                    {
                        from: 1,
                        to: 5,
                        name: 'Низкая',
                        color: '#decb0e'
                    },
                    {
                        from: 6,
                        to: 12,
                        name: 'Средняя',
                        color: '#5e9c20'
                    },
                    {
                        from: 13,
                        to: 20,
                        name: 'Высокая',
                        color: '#ffab00'
                    },
                    {
                        from: 20,
                        to: 99,
                        name: 'Аномальная',
                        color: '#ee0c04'
                    }
                    ]
                }
            }
        },
        grid: {
            borderColor: '#535A6C',
            // xaxis: {
            //     lines: {
            //         show: false,
            //     },
            // },
            // yaxis: {
            //     lines: {
            //         show: false,
            //     },
            // }
        },

        xaxis: {
            // labels: {
            //     style: {
            //         colors: ['#fff', '#fff', '#fff', '#fff']
            //     }
            // },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            }
        },
        yaxis: {
            // labels: {
            //     style: {
            //         colors: ['#fff']
            //     }
            // },
        },
        dataLabels: {
            enabled: true,
            // style: {
            //     colors: ['#fff']
            // }

        },
        stroke: {
            width: 1
        },
        title: {
            text: 'Активность Hesoyam II (сделок в неделю 2023)',
            labels: {
                style: {
                    colors: ['#fff']
                }
            }
        },
        legend: {
            position: "top",
            // labels: {
            //     colors: ['#fff']
            // }
        }
    }

    return newOptions
}

// The function that returns the options for the Heat Map chart
export const barChartSpaceOptions = () => {
    const newOptions = {
        chart: {
            height: 170,
            stacked: true,
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                // columnWidth: "30%",
                horizontal: true,
            },
        },
        xaxis: {
            categories: ["Data Base"],
            max: 35000,
            tickAmount: 4,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            }
        },
        fill: {
            opacity: 1,
        },
        colors: ["#f0b64d", "#4ead98"], //Edit Colors Here
        legend: {
            position: "top",
            horizontalAlign: "center",
            offsetX: -20,
        },
    }

    return newOptions
}


export const barChartDayOptions = () => {
    const newOptions = {
        chart: {
            height: 170,
            type: "bar",
            stacked: true,
            foreColor: '#ccc',
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                // columnWidth: "30%",
                horizontal: true,
            },
        },
        xaxis: {
            categories: ["Cycle Dur."],
            max: 35000 / 4 / 24,
            tickAmount: 4,
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            }
        },
        fill: {
            opacity: 1,
        },
        colors: ["#ad4d38", "#a2a3a5"], //Edit Colors Here
        legend: {
            position: "top",
            horizontalAlign: "center",
            offsetX: -20,
        },
    }

    return newOptions
}