import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { useExcelData } from '../../hooks/useExcelData';
import { barChartSpaceOptions, barChartDayOptions } from '../../utils/chart-tools';
import Spinner from '../Spinner';
import Error from '../Error';

const BarCharts = () => {
    const [optionsBarSpace, setOptionsBarSpace] = useState({})
    const [seriesBarSpace, setSeriesBarSpace] = useState([])
    const [optionsBarDay, setOptionsBarDay] = useState({})
    const [seriesBarDay, setSeriesBarDay] = useState([])


    const { data, loading, error } = useExcelData('API-out-stats', 'bar')
    console.log('Data: ', data);

    useEffect(() => {
        if (data) {
            let db = Number(data[0]['Database']);

            const newSeriesBarSpace = [
                {
                    name: "Used Space",
                    data: [db],
                },
                {
                    name: "Total Space",
                    data: [(35000 - db)],
                },
            ];

            setSeriesBarSpace(newSeriesBarSpace);

            const newSeriesBarDay = [
                {
                    name: "Days Used",
                    data: [parseInt(db / 4 / 24)],
                },
                {
                    name: "Days Left",
                    data: [parseInt((35000 - db) / 4 / 24)],
                },
            ]

            setSeriesBarDay(newSeriesBarDay);

            const newOptionsBarSpace = barChartSpaceOptions();
            setOptionsBarSpace(newOptionsBarSpace)

            const newOptionsBarDay = barChartDayOptions();
            setOptionsBarDay(newOptionsBarDay)
        }
    }, [data])

    // Error handling
    if (error) return <Error />


    return (
        <div className='bg-dark'>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <Chart
                        options={optionsBarSpace}
                        series={seriesBarSpace}
                        height={170}
                        type="bar"
                    />
                    <Chart
                        options={optionsBarDay}
                        series={seriesBarDay}
                        height={170}
                        type="bar"
                    />
                </>
            )}
        </div>
    );
}

export default BarCharts;
