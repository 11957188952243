import React, { useEffect } from 'react';

const Feed = () => {
    useEffect(() => {
        const widgetContainer = document.getElementById("coinmarket-widget");
        const widget = document.getElementById("coinmarketcap-widget-marquee");
        widgetContainer.appendChild(widget);
    }, []);

    return (
        <div className='bg-dark feed-section'>
            <div id="coinmarket-widget"></div>
            {/* https://codesandbox.io/s/my-sad-attempt-at-getting-a-html-js-widget-in-reactjs-forked-d2lbi?file=/src/App.js */}
            {/* https://stackoverflow.com/questions/70990424/how-to-turn-html-widget-code-into-nextjs-code-to-use-in-an-app-coinmarketcap-pr */}
        </div>
    );
}

export default Feed;
